import React from 'react'
import i1 from "../assets/ab1.jpg";
import i2 from "../assets/ab2.jpg";
import i3 from "../assets/ab3.jpg";
import i4 from "../assets/ab4.jpg";
import i5 from "../assets/ab5.jpg";
import i6 from "../assets/ab6.jpg";
import i11 from "../assets/ab7.jpg";
import i12 from "../assets/ab8.jpg";
import i13 from "../assets/ab9.jpg";
import i14 from "../assets/ab11.jpg";
import i15 from "../assets/ab12.jpg";
import i16 from "../assets/ab14.jpg";
import i17 from "../assets/ab17.jpg";

import { ExternalLink } from 'react-external-link';
import Marquee from "react-fast-marquee";

const Someofourcustomers = () => {
  const imageStyle = {
    width: '200px', // adjust as needed
    height: '200px', // adjust as needed
    borderRadius: '5px', // adjust as needed
    objectFit: 'cover', // to maintain aspect ratio
    margin: '0 30px', // add horizontal margin
  };

  return (
    <div className='flex gap-3 mt-4 flex-wrap mb-10'>
      <div class="flex flex-row text-2xl font-bold justify-center items-center" style={{textAlign: 'center', width: '100%'}}>
      </div>
      <div>
        <Marquee pauseOnHover={true} speed={50}>
          <ExternalLink href='#' className='tab-rounded-lg'>
            <img src={i1} style={imageStyle} />
          </ExternalLink>

          <ExternalLink href='#' className='tab-rounded-lg'>
            <img src={i2} style={imageStyle} />
          </ExternalLink>

          <ExternalLink href='#' className='tab-rounded-lg'>
            <img src={i3} style={imageStyle} />
          </ExternalLink>

          <ExternalLink href='#' className='tab-rounded-lg'>
            <img src={i4} style={imageStyle} />
          </ExternalLink>

          <ExternalLink href='#' className='tab-rounded-lg'>
            <img src={i5} style={imageStyle} />
          </ExternalLink>

          <ExternalLink href='#' className='tab-rounded-lg'>
            <img src={i6} style={imageStyle} />
          </ExternalLink>

          <ExternalLink href='#' className='tab-rounded-lg'>
            <img src={i11} style={imageStyle} />
          </ExternalLink>

          <ExternalLink href='#' className='tab-rounded-lg'>
            <img src={i12} style={imageStyle} />
          </ExternalLink>

          <ExternalLink href='#' className='tab-rounded-lg'>
            <img src={i13} style={imageStyle} />
          </ExternalLink>

          <ExternalLink href='#' className='tab-rounded-lg'>
            <img src={i14} style={imageStyle} />
          </ExternalLink>

          <ExternalLink href='#' className='tab-rounded-lg'>
            <img src={i15} style={imageStyle} />
          </ExternalLink>

          <ExternalLink href='#' className='tab-rounded-lg'>
            <img src={i16} style={imageStyle} />
          </ExternalLink>
        </Marquee>
      </div>
    </div>
  )
}

export default Someofourcustomers

