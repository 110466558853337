
import logo from "../assets/prospority.jpg";
import React, { useState } from 'react';
import axios from 'axios';
import i1 from "../assets/medemer.jpg";

const RegisterForm = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');
  const [isRegistered, setIsRegistered] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleRegister = async () => {
    try {
      const response = await axios.post('https://api.eth-prosperity.com/api/register/', {
        username,
        password,
        email
      });
      console.log(response.data); // Handle the response data here
      setIsRegistered(true);
      setErrorMessage('');
    } catch (error) {
      console.error(error);
      setIsRegistered(false);
      setErrorMessage('Bad request. Please check your registration details.');
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100" style={{backgroundImage: `url(${i1})`}}>
      <div className="max-w-sm">
        <div className="bg-white shadow-md rounded px-8 py-6">
          <h2 className="text-2xl font-bold mb-4">Register a User</h2>

          {isRegistered && (
            <p className="text-green-500 mb-4">Registered successfully!</p>
          )}

          {errorMessage && (
            <p className="text-red-500 mb-4">{errorMessage}</p>
          )}

          <img src={logo} className="w-16 h-16 mb-4" alt="Logo" />

          <input
            type="text"
            placeholder="Username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            className="w-full px-4 py-2 mb-4 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
          />

          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="w-full px-4 py-2 mb-4 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
          />

          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="w-full px-4 py-2 mb-4 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
          />

          <button
            onClick={handleRegister}
            className="w-full px-4 py-2 text-white bg-blue-500 rounded hover:bg-blue-600 focus:outline-none"
          >
            Register
          </button>
        </div>
      </div>
    </div>
  );
};

export default RegisterForm;