import React, { useState } from 'react';
import i1 from '../assets/medemer.jpg'; // Assuming the background image is located in the assets folder

const MediaLinksCard = () => {
    const mediaLinks = [
        { name: 'CNN', url: 'https://www.cnn.com/' },
        { name: 'BBC', url: 'https://www.bbc.com/' },
        { name: 'ETHIO251', url: 'https://www.facebook.com/ETHIO251/' },
        { name: 'Ethio360', url: 'https://www.facebook.com/Ethio360mediaOfficial' },
        { name: 'Zehabesha', url: 'https://www.facebook.com/Zehabesha' },
        { name: 'Aljazeera', url: 'https://www.aljazeera.com/' },
        { name: 'Feradaily', url: 'https://www.youtube.com/@fetadailyanalysis' },
        { name: 'Anchor Media', url: 'https://www.youtube.com/@mesaymekonnen5' },
        { name: 'Roha Media', url: 'https://www.youtube.com/@roha_tv' },
        { name: 'Andafta', url: 'https://www.youtube.com/@Andafta' },
        { name: 'Feradaily', url: 'https://www.youtube.com/@fetadailyanalysis' },
        { name: 'Anchor Media', url: 'https://www.youtube.com/@mesaymekonnen5' },
        { name: 'EthioTimes', url: 'https://www.youtube.com/@EthioTimes' },
        { name: 'ZeEthiop', url: 'https://www.facebook.com/ZeEthiop' },
        { name: 'BBCnewsAmharic', url: 'https://www.facebook.com/BBCnewsAmharic' },
        // Add more media links as needed
    ];

    const [checkedLinks, setCheckedLinks] = useState({});
    const [uploadedImages, setUploadedImages] = useState({});
    const [showFullScreenImage, setShowFullScreenImage] = useState(null); // Indicates which image to display in full-screen

    // Handle checkbox change
    const handleCheckboxChange = (name) => {
        setCheckedLinks({
            ...checkedLinks,
            [name]: !checkedLinks[name]
        });
    };

    // Handle file upload and store the image
    const handleFileUpload = (link, event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = () => {
                setUploadedImages((prev) => ({
                    ...prev,
                    [link.name]: reader.result
                }));
            };
            reader.readAsDataURL(file);
        }
    };

    // Function to handle clicking on the small image
    const handleSmallImageClick = (linkName) => {
        setShowFullScreenImage(linkName);
    };

    // Function to handle closing the full-screen image display
    const handleCloseFullScreenImage = () => {
        setShowFullScreenImage(null);
    };

    return (
        <div className="flex flex-col items-center mt-3">
            {/* <div className="bg-white shadow-md rounded-lg p-6 w-1/2 bg-cover bg-center" style={{ backgroundImage: `url(${i1})` }}> */}
            <div className="bg-white shadow-md rounded-lg p-6 w-full sm:w-1/2 bg-cover bg-center" style={{ backgroundImage: `url(${i1})` }}>
                <h2 className="text-xl font-bold mb-4 text-white">Media Links</h2>
                <ul>
                    {mediaLinks.map((link) => (
                        <li key={link.name} className="flex items-center mb-3 hover:bg-gray-200 p-2 rounded-md transition-all ">
                            <input
                                type="checkbox"
                                className="mr-2"
                                checked={checkedLinks[link.name] || false}
                                onChange={() => handleCheckboxChange(link.name)}
                            />
                            <a
                                href={link.url}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="hover:text-blue-600 text-white hover:underline"
                            >
                                {link.name}
                            </a>
                            {/* Save Screenshot Button */}
                            {checkedLinks[link.name] && (
                                <div className="ml-4">
                                    <input
                                        type="file"
                                        accept="image/*"
                                        onChange={(e) => handleFileUpload(link, e)}
                                        className="hidden" // hide the input
                                        id={`upload-${link.name}`}
                                    />
                                    <label
                                        htmlFor={`upload-${link.name}`}
                                        className="text-sm text-white bg-blue-600 hover:bg-blue-700 p-1 rounded cursor-pointer"
                                    >
                                        Upload Image
                                    </label>
                                    {/* Display the uploaded image if available */}
                                    {uploadedImages[link.name] && (
                                        <img
                                            src={uploadedImages[link.name]}
                                            alt={`${link.name} Uploaded`}
                                            className="mt-2 w-24 h-24 rounded cursor-pointer"
                                            onClick={() => handleSmallImageClick(link.name)}
                                        />
                                    )}
                                </div>
                            )}
                        </li>
                    ))}
                </ul>
            </div>
            {/* Full-Screen Image Display */}
            {showFullScreenImage && (
                <div
                    className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-75 z-50"
                    onClick={handleCloseFullScreenImage}
                >
                    <img
                        src={uploadedImages[showFullScreenImage]}
                        alt={`${showFullScreenImage} Full Screen`}
                        className="max-w-full max-h-full"
                    />
                </div>
            )}
        </div>
    );
};

export default MediaLinksCard;
