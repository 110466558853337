// import { useMemo } from 'react';
// import {
//   MaterialReactTable,
//   useMaterialReactTable,
// } from 'material-react-table';
// import { data } from './makeData';

// const Example = () => {
//   const columns = useMemo(
//     () => [
//       {
//         accessorKey: 'firstName',
//         header: 'First Name',
//         size: 100,
//         muiTableHeadCellProps: {
//           align: 'center',
//         },
//         muiTableBodyCellProps: {
//           align: 'center',
//         },
//       },
//       {
//         accessorKey: 'lastName',
//         header: 'Last Name',
//         size: 100,
//         muiTableHeadCellProps: {
//           align: 'center',
//         },
//         muiTableBodyCellProps: {
//           align: 'center',
//         },
//       },
//       {
//         accessorKey: 'age',
//         header: 'Age',
//         muiTableHeadCellProps: {
//           align: 'right',
//         },
//         muiTableBodyCellProps: {
//           align: 'right',
//         },
//       },
//       {
//         accessorKey: 'salary',
//         header: 'Salary',
//         muiTableHeadCellProps: {
//           align: 'right',
//         },
//         muiTableBodyCellProps: {
//           align: 'right',
//         },
//         Cell: ({ cell }) =>
//           cell
//             .getValue()
//             .toLocaleString('en-US', { style: 'currency', currency: 'USD' }),
//       },
//     ],
//     [],
//   );

//   const table = useMaterialReactTable({
//     columns,
//     data,
//   });

//   return <MaterialReactTable table={table} />;
// };

// export default Example;

// import { useMemo } from 'react';
// import {
//  MaterialReactTable,
//  useMaterialReactTable,
// } from 'material-react-table';
// import { data } from './makeData'; // Ensure this path is correct   

// const Example = () => {
//  const columns = useMemo(
//     () => [
//       {
//         accessorKey: 'firstName',
//         header: 'First Name',
//         size: 100,
//         muiTableHeadCellProps: {
//           align: 'center',
//         },
//         muiTableBodyCellProps: {
//           align: 'center',
//         },
//       },
//       {
//         accessorKey: 'lastName',
//         header: 'Last Name',
//         size: 100,
//         muiTableHeadCellProps: {
//           align: 'center',
//         },
//         muiTableBodyCellProps: {
//           align: 'center',
//         },
//       },
//       {
//         accessorKey: 'age',
//         header: 'Age',
//         muiTableHeadCellProps: {
//           align: 'right',
//         },
//         muiTableBodyCellProps: {
//           align: 'right',
//         },
//       },
//       {
//         accessorKey: 'salary',
//         header: 'Salary',
//         muiTableHeadCellProps: {
//           align: 'right',
//         },
//         muiTableBodyCellProps: {
//           align: 'right',
//         },
//         Cell: ({ cell }) =>
//           cell
//             .getValue()
//             .toLocaleString('en-US', { style: 'currency', currency: 'USD' }),
//       },
//     ],
//     [],
//  );

//  const table = useMaterialReactTable({
//     columns,
//     data,
//  });

//  return <MaterialReactTable table={table} />;
// };

// export default Example;
// import React, { useState, useEffect, useMemo } from 'react';
// import {
//   MaterialReactTable,
//   useMaterialReactTable,
// } from 'material-react-table';
// import { CircularProgress } from '@mui/material';

// const Example = () => {
//   const [sentReports, setSentReports] = useState([]);
//   const [senderInfo, setSenderInfo] = useState(null);
//   const [loading, setLoading] = useState(true);

//   useEffect(() => {
//     const fetchSentReports = async () => {
//       try {
//         const apiUrl = 'http://127.0.0.1:8000//api/api/reports/';
//         const response = await fetch(apiUrl);
//         if (!response.ok) {
//           throw new Error('Failed to fetch sent reports');
//         }
//         const data = await response.json();
//         setSentReports(data);
//         setLoading(false);
//       } catch (error) {
//         console.error('Error fetching sent reports:', error.message);
//         setLoading(false);
//       }
//     };
//     fetchSentReports();
//   }, []);

//   const columns = useMemo(
//     () => [
//       { accessorKey: 'id', header: 'Number', size: 50 },
//       { accessorKey: 'report_date', header: 'Report Date', size: 100 },
//       { accessorKey: 'username', header: 'User', size: 100 },
//       { accessorKey: 'twitter', header: 'Twitter', size: 100 },
//       { accessorKey: 'facebook_page', header: 'Facebook Page', size: 150 },
//       { accessorKey: 'facebook_user', header: 'Facebook User', size: 150 },
//       { accessorKey: 'tiktok', header: 'TikTok', size: 100 },
//       { accessorKey: 'instagram', header: 'Instagram', size: 100 },
//     ],
//     [],
//   );

//   const table = useMaterialReactTable({
//     columns,
//     data: sentReports,
//     state: { isLoading: loading },
//     initialState: {
//       showColumnFilters: false,
//     },
//   });

//   return (
//     <div className="p-6">
//       <h2 className="text-2xl font-bold mb-4">All Accounts</h2>
//       {loading ? (
//         <CircularProgress />
//       ) : (
//         <MaterialReactTable table={table} />
//       )}
//     </div>
//   );
// };

// export default Example;



import React, { useState, useEffect, useMemo } from 'react';
import {
  MaterialReactTable,
  useMaterialReactTable,
} from 'material-react-table';
import { CircularProgress } from '@mui/material';

const SentReportTable = () => {
  const [sentReports, setSentReports] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchSentReports = async () => {
      try {
        const apiUrl = 'https://api.eth-prosperity.com/api/api/reports/';
        const response = await fetch(apiUrl);
        if (!response.ok) {
          throw new Error('Failed to fetch sent reports');
        }
        const data = await response.json();
        setSentReports(data);
      } catch (error) {
        console.error('Error fetching sent reports:', error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchSentReports();
  }, []);

  const columns = useMemo(
    () => [
      { accessorKey: 'id', header: 'Number', size: 50 },
      { accessorKey: 'report_date', header: 'Report Date', size: 100 },
      { accessorKey: 'username', header: 'User', size: 100 },
      { accessorKey: 'twitter', header: 'Twitter', size: 100 },
      { accessorKey: 'facebook_page', header: 'Facebook Page', size: 150 },
      { accessorKey: 'facebook_user', header: 'Facebook User', size: 150 },
      { accessorKey: 'tiktok', header: 'TikTok', size: 100 },
      { accessorKey: 'instagram', header: 'Instagram', size: 100 },
    ],
    [],
  );

  const table = useMaterialReactTable({
    columns,
    data: sentReports,
    state: { isLoading: loading },
    initialState: {
      showColumnFilters: false,
    },
  });

  return (
    <div className="p-6">
      <h2 className="text-2xl font-bold mb-4">All Accounts</h2>
      {loading ? (
        <CircularProgress />
      ) : (
        <MaterialReactTable table={table} />
      )}
    </div>
  );
};

export default SentReportTable;



