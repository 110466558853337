// import React, { useState } from 'react';
// import DatePicker from 'react-datepicker';
// import 'react-datepicker/dist/react-datepicker.css';
// import { useReactToPrint } from 'react-to-print';
// import securelocalStorage from 'react-secure-storage';
// import i1 from "../assets/medemer.jpg";

// const Accounttable = () => {
//   const [accounts, setAccounts] = useState([
//     {
//       id: 1,
//       user: securelocalStorage.getItem('userId') || 1,
//       facebookNumber: '',
//       facebookLink: '',
//       facebookPageNumber: '',
//       facebookPageLink: '',
//       twitterNumber: '',
//       twitterLink: '',
//       tiktokNumber: '',
//       tiktokLink: '',
//     },
//   ]);

//   const [reportDate, setReportDate] = useState(new Date());

//   const addAccount = () => {
//     const newAccount = {
//       id: accounts.length + 1,
//       user: securelocalStorage.getItem('userId') || 1,
//       facebookNumber: '',
//       facebookLink: '',
//       facebookPageNumber: '',
//       facebookPageLink: '',
//       twitterNumber: '',
//       twitterLink: '',
//       tiktokNumber: '',
//       tiktokLink: '',
//     };

//     setAccounts([...accounts, newAccount]);
//   };

//   const sendReport = async () => {
//     try {
//       const apiUrl = 'https://api.eth-prosperity.com/api/api/accountreports/';

//       const postData = accounts.map(account => ({
//         id: account.id,
//         user: account.user,
//         report_date: reportDate.toISOString().split('T')[0],
//         twitter_number: account.twitterNumber,
//         twitter_link: account.twitterLink,
//         facebook_page_number: account.facebookPageNumber,
//         facebook_page_link: account.facebookPageLink,
//         facebook_user_number: account.facebookNumber,
//         facebook_user_link: account.facebookLink,
//         tiktok_number: account.tiktokNumber,
//         tiktok_link: account.tiktokLink,
//       }));

//       const response = await fetch(apiUrl, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify(postData),
//       });

//       if (!response.ok) {
//         throw new Error('Failed to send report');
//       }

//       setAccounts([
//         {
//           id: 1,
//           user: securelocalStorage.getItem('userId') || 1,
//           facebookNumber: '',
//           facebookLink: '',
//           facebookPageNumber: '',
//           facebookPageLink: '',
//           twitterNumber: '',
//           twitterLink: '',
//           tiktokNumber: '',
//           tiktokLink: '',
//         },
//       ]);

//       alert('Report sent to the manager!');
//     } catch (error) {
//       console.error('Error sending report:', error.message);
//       alert('Failed to send report. Please try again.');
//     }
//   };

//   const componentRef = React.useRef();

//   const handlePrint = useReactToPrint({
//     content: () => componentRef.current,
//   });

//   const handleInputChange = (index, field, value) => {
//     const updatedAccounts = [...accounts];
//     updatedAccounts[index][field] = value;
//     setAccounts(updatedAccounts);
//   };

//   return (
//     <div className="p-6">
//       <div className="mb-4">
//         <label className="mr-2">Report Date:</label>
//         <DatePicker selected={reportDate} onChange={(date) => setReportDate(date)} />
//       </div>

//       {/* <div className="bg-white p-6 rounded-md shadow-md" ref={componentRef}> */}
//       <div className="p-6 rounded-md shadow-md" style={{ backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), url(${i1})`, backgroundSize: 'cover', backgroundPosition: 'center' }} ref={componentRef}>
//         <button
//           className="mb-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
//           onClick={addAccount}
//         >
//           Add Account
//         </button>

//         <div className="overflow-x-auto">
//           <table className="min-w-full border border-gray-300">
//             <thead>
//               <tr>
//                 <th className="border-b p-2">Number</th>
//                 <th className="border-b p-2">Facebook Number</th>
//                 <th className="border-b p-2">Facebook Link</th>
//                 <th className="border-b p-2">Facebook Page Number</th>
//                 <th className="border-b p-2">Facebook Page Link</th>
//                 <th className="border-b p-2">Twitter Number</th>
//                 <th className="border-b p-2">Twitter Link</th>
//                 <th className="border-b p-2">TikTok Number</th>
//                 <th className="border-b p-2">TikTok Link</th>
//               </tr>
//             </thead>
//             <tbody>
//               {accounts.map((account, index) => (
//                 <tr key={account.id}>
//                   <td className="border-b p-2">{account.id}</td>
//                   <td className="border-b p-2">
//                     <input
//                       type="text"
//                       value={account.facebookNumber}
//                       onChange={(e) => handleInputChange(index, 'facebookNumber', e.target.value)}
//                       className="w-full p-1"
//                     />
//                   </td>
//                   <td className="border-b p-2">
//                     <input
//                       type="text"
//                       value={account.facebookLink}
//                       onChange={(e) => handleInputChange(index, 'facebookLink', e.target.value)}
//                       className="w-full p-1"
//                     />
//                   </td>
//                   <td className="border-b p-2">
//                     <input
//                       type="text"
//                       value={account.facebookPageNumber}
//                       onChange={(e) => handleInputChange(index, 'facebookPageNumber', e.target.value)}
//                       className="w-full p-1"
//                     />
//                   </td>
//                   <td className="border-b p-2">
//                     <input
//                       type="text"
//                       value={account.facebookPageLink}
//                       onChange={(e) => handleInputChange(index, 'facebookPageLink', e.target.value)}
//                       className="w-full p-1"
//                     />
//                   </td>
//                   <td className="border-b p-2">
//                     <input
//                       type="text"
//                       value={account.twitterNumber}
//                       onChange={(e) => handleInputChange(index, 'twitterNumber', e.target.value)}
//                       className="w-full p-1"
//                     />
//                   </td>
//                   <td className="border-b p-2">
//                     <input
//                       type="text"
//                       value={account.twitterLink}
//                       onChange={(e) => handleInputChange(index, 'twitterLink', e.target.value)}
//                       className="w-full p-1"
//                     />
//                   </td>
//                   <td className="border-b p-2">
//                     <input
//                       type="text"
//                       value={account.tiktokNumber}
//                       onChange={(e) => handleInputChange(index, 'tiktokNumber', e.target.value)}
//                       className="w-full p-1"
//                     />
//                   </td>
//                   <td className="border-b p-2">
//                     <input
//                       type="text"
//                       value={account.tiktokLink}
//                       onChange={(e) => handleInputChange(index, 'tiktokLink', e.target.value)}
//                       className="w-full p-1"
//                     />
//                   </td>
//                 </tr>
//               ))}
//             </tbody>
//           </table>
//         </div>

//         <div className="mt-4 flex justify-between">
//           <button
//             className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
//             onClick={sendReport}
//           >
//             Send Report
//           </button>

//           <button
//             className="bg-yellow-500 hover:bg-yellow-700 text-white font-bold py-2 px-4 rounded"
//             onClick={handlePrint}
//           >
//             Save as PDF
//           </button>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Accounttable;







import React, { useState, useEffect, useMemo } from 'react';
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import { CircularProgress } from '@mui/material';
import securelocalStorage from 'react-secure-storage';

const AccountReportTable = () => {
  const [sentReports, setSentReports] = useState([]);
  const [senderInfo, setSenderInfo] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchSentReports = async () => {
      try {
        const apiUrl = 'https://api.eth-prosperity.com/api/api/accountreports/';
        const response = await fetch(apiUrl);
        if (!response.ok) {
          throw new Error('Failed to fetch sent reports');
        }

        const data = await response.json();
        setSentReports(data);

        // Fetch sender's information based on the user ID from the first report
        if (data.length > 0) {
          const senderId = data[0].user;
          const senderApiUrl = `https://api.eth-prosperity.com/api/api/users/${senderId}/`;

          const senderResponse = await fetch(senderApiUrl);
          if (senderResponse.ok) {
            const senderData = await senderResponse.json();
            setSenderInfo(senderData);
          }
        }
      } catch (error) {
        console.error('Error fetching sent reports:', error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchSentReports();
  }, []);

  const columns = useMemo(
    () => [
      { accessorKey: 'id', header: 'ID', size: 50 },
      { accessorKey: 'report_date', header: 'Report Date', size: 100 },
      { accessorKey: 'username', header: 'User', size: 100 },
      { accessorKey: 'twitter_number', header: 'Twitter Number', size: 100 },
      { accessorKey: 'twitter_link', header: 'Twitter Link', size: 150 },
      { accessorKey: 'facebook_page_number', header: 'Facebook Page Number', size: 100 },
      { accessorKey: 'facebook_page_link', header: 'Facebook Page Link', size: 150 },
      { accessorKey: 'facebook_user_number', header: 'Facebook User Number', size: 100 },
      { accessorKey: 'facebook_user_link', header: 'Facebook User Link', size: 150 },
      { accessorKey: 'tiktok_number', header: 'TikTok Number', size: 100 },
      { accessorKey: 'tiktok_link', header: 'TikTok Link', size: 150 },
    ],
    [],
  );

  const table = useMaterialReactTable({
    columns,
    data: sentReports,
    state: { isLoading: loading },
    initialState: {
      showColumnFilters: false,
    },
  });

  return (
    <div className="p-6">
      {senderInfo && (
        <div className="mb-4">
          <h2 className="text-2xl font-bold">Sender Information</h2>
          <p>Name: {senderInfo.name}</p>
          <p>Email: {senderInfo.email}</p>
          {/* Add any other user information you want to display */}
        </div>
      )}

      {/* <div className="bg-blue-300 p-6 rounded-md shadow-md"> */}
      <div className="p-6 rounded-md shadow-lg">
        <h2 className="text-2xl font-bold mb-4">All Accounts</h2>
        {loading? (
          <CircularProgress />
        ) : (
          <MaterialReactTable table={table} />
        )}
      </div>
    </div>
  );
};

export default AccountReportTable;