import React, { useState, useEffect } from 'react';
import i1 from "../assets/medemer.jpg";

function ChangePasswordComponent() {
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(true); // Set initial loading state to true
  const [isClosing, setIsClosing] = useState(false);

  const handleChange = (e) => {
    if (e.target.name === 'oldPassword') {
      setOldPassword(e.target.value);
    } else if (e.target.name === 'newPassword') {
      setNewPassword(e.target.value);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Create the request body
    const requestBody = {
      old_password: oldPassword,
      new_password: newPassword,
    };

    // Retrieve the token from local storage
    const token = localStorage.getItem('token');

    setIsLoading(true); // Start loading

    try {
      // Make the API request with the token
      const response = await fetch('https://api.eth-prosperity.com/api/change_password/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `token ${token}`, // Include the token in the request headers
        },
        body: JSON.stringify(requestBody),
      });

      if (response.ok) {
        const data = await response.json();
        setMessage(data.message);
        setError('');
      } else if (response.status === 401) {
        setError('Unauthorized: Please make sure your credentials are correct.');
      } else {
        throw new Error('Request failed with status ' + response.status);
      }
    } catch (error) {
      console.error('Error:', error);
      setError('An error occurred while processing your request.');
    } finally {
      setIsLoading(false); // Stop loading
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 2000); // Simulate a 2-second loading time for the Home component

    return () => clearTimeout(timer);
  }, []); // Empty dependency array ensures the effect runs only once on component mount

  return (
    <div className="flex items-center justify-center h-screen bg-blue-100" style={{backgroundImage: `url(${i1})`}}>
      <div className="bg-white p-8 rounded-lg relative">
        <h2 className="text-2xl font-bold mb-4 text-center">Change Password</h2>

        <button
          className="absolute top-2 right-2 bg-red-700 hover:bg-red-800 text-white rounded-full w-8 h-8 flex items-center justify-center"
          onClick={async () => {
            setIsClosing(true);
            await new Promise((resolve) => setTimeout(resolve, 500)); // Wait for 500ms
            window.location.href = '/';
          }}
        >
          {isClosing ? (
            <div className="animate-spin rounded-full h-6 w-6 border-t-2 border-b-2 border-white"></div>
          ) : (
            'X'
          )}
        </button>

        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="block mb-1">Old Password:</label>
            <input
              type="password"
              name="oldPassword"
              placeholder="Enter old password"
              value={oldPassword}
              onChange={handleChange}
              className="border border-gray-300 bg-white text-sm h-10 px-3 rounded focus:outline-none focus:ring-2 focus:ring-blue-500 w-full"
            />
          </div>
          <div className="mb-4">
            <label className="block mb-1">New Password:</label>
            <input
              type="password"
              name="newPassword"
              placeholder="Enter new password"
              value={newPassword}
              onChange={handleChange}
              className="border border-gray-300 bg-white text-sm h-10 px-3 rounded focus:outline-none focus:ring-2 focus:ring-blue-500 w-full"
            />
          </div>
          <div className="flex justify-center">
            <button
              className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-16 rounded focus:outline-none"
              type="submit"
              disabled={isLoading} // Disable the button while loading
            >
              {isLoading ? 'Loading...' : 'Change Password'}
            </button>
          </div>
        </form>
        {error && <div className="text-red-900 mt-2">{error}</div>}
        {message && <div className="text-yellow-500 mt-2">{message}</div>}
      </div>
      {isLoading && (
        <div className="fixed top-0 left-0 bottom-0 right-0 flex items-center justify-center bg-gray-500 bg-opacity-50">
          <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-b-4 border-white"></div>
        </div>
      )}
    </div>
  );
}

export default ChangePasswordComponent;