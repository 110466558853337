// // SentReportTable.js
// import React, { useState, useEffect } from 'react';

// const SentReportTable = () => {
//   const [sentReports, setSentReports] = useState([]);

//   useEffect(() => {
//     const fetchSentReports = async () => {
//       try {
//         const apiUrl = 'http://192.168.8.4:8000/api/api/reports/';

//         const response = await fetch(apiUrl);
//         if (!response.ok) {
//           throw new Error('Failed to fetch sent reports');
//         }

//         const data = await response.json();
//         setSentReports(data);
//       } catch (error) {
//         console.error('Error fetching sent reports:', error.message);
//       }
//     };

//     fetchSentReports();
//   }, []);

//   return (
//     <div className="p-6">
//       <div className="bg-blue-300 p-6 rounded-md shadow-md">
//         <h2 className="text-2xl font-bold mb-4">All Accounts</h2>
//         <div className="overflow-x-auto">
//           <table className="min-w-full border border-gray-300">
//             <thead>
//               <tr>
//                 <th className="border-b p-2">Number</th>
//                 <th className="border-b p-2">Twitter</th>
//                 <th className="border-b p-2">Facebook Page</th>
//                 <th className="border-b p-2">Facebook User</th>
//                 <th className="border-b p-2">TikTok</th>
//                 <th className="border-b p-2">Instagram</th>
//               </tr>
//             </thead>
//             <tbody>
//               {sentReports.map((report) => (
//                 <tr key={report.id} className="hover:bg-yellow-100 hover:cursor-pointer">
//                   <td className="border-b p-2">{report.id}</td>
//                   <td className="border-b p-2">{report.twitter}</td>
//                   <td className="border-b p-2">{report.facebook_page}</td>
//                   <td className="border-b p-2">{report.facebook_user}</td>
//                   <td className="border-b p-2">{report.tiktok}</td>
//                   <td className="border-b p-2">{report.instagram}</td>
//                 </tr>
//               ))}
//             </tbody>
//           </table>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default SentReportTable;

// SentReportTable.js
import React, { useState, useEffect } from 'react';
import i1 from "../assets/medemer.jpg";

const SentReportTable = () => {
  const [sentReports, setSentReports] = useState([]);
  const [senderInfo, setSenderInfo] = useState(null);

  useEffect(() => {
    const fetchSentReports = async () => {
      try {
        const apiUrl = ' https://api.eth-prosperity.com/api/api/reports/';

        const response = await fetch(apiUrl);
        if (!response.ok) {
          throw new Error('Failed to fetch sent reports');
        }

        const data = await response.json();
        setSentReports(data);

        // Fetch sender's information based on the user ID from the first report
        if (data.length > 0) {
          const senderId = data[0].user;
          const senderApiUrl = `https://api.eth-prosperity.com/api/api/users/${senderId}/`;

          const senderResponse = await fetch(senderApiUrl);
          if (senderResponse.ok) {
            const senderData = await senderResponse.json();
            setSenderInfo(senderData);
          }
        }
      } catch (error) {
        console.error('Error fetching sent reports:', error.message);
      }
    };

    fetchSentReports();
  }, []);

  return (
    <div className="p-6">
      {senderInfo && (
        <div className="mb-4">
          <h2 className="text-2xl font-bold">Sender Information</h2>
          <p>Name: {senderInfo.name}</p>
          <p>Email: {senderInfo.email}</p>
          {/* Add any other user information you want to display */}
        </div>
      )}

      <div className="bg-blue-300 p-6 rounded-md shadow-md">
      {/* <div className="p-6" style={{ backgroundImage: `url(${i1})`, backgroundSize: 'cover', backgroundPosition: 'center', backgroundBlendMode: 'multiply', backgroundColor: 'rgba(255, 255, 255, 0.5)' }}> */}
        <h2 className="text-2xl font-bold mb-4">All Accounts</h2>
        <div className="overflow-x-auto">
          <table className="min-w-full border border-gray-300">
            <thead>
              <tr>
              <th className="border-b p-2">Number</th>
                <th className="border-b p-2">Report Date</th>
                <th className="border-b p-2">user</th>
                <th className="border-b p-2">Twitter</th>
                <th className="border-b p-2">Facebook Page</th>
                <th className="border-b p-2">Facebook User</th>
                <th className="border-b p-2">TikTok</th>
                <th className="border-b p-2">Instagram</th>
              </tr>
            </thead>
            <tbody>
              {sentReports.map((report) => (
                <tr key={report.id} className="hover:bg-yellow-100 hover:cursor-pointer">
                  <td className="border-b p-2">{report.id}</td>
                  <td className="border-b p-2">{report.report_date}</td>
                  <td className="border-b p-2 ">{report.username}</td>
                  <td className="border-b p-2">{report.twitter}</td>
                  <td className="border-b p-2">{report.facebook_page}</td>
                  <td className="border-b p-2">{report.facebook_user}</td>
                  <td className="border-b p-2">{report.tiktok}</td>
                  <td className="border-b p-2">{report.instagram}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default SentReportTable;
