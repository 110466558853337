// // ReportTable.js
// import React, { useState } from 'react';
// import DatePicker from 'react-datepicker';
// import 'react-datepicker/dist/react-datepicker.css';
// import { useReactToPrint } from 'react-to-print';
// import securelocalStorage from 'react-secure-storage'
// import i1 from "../assets/medemer.jpg";

// const ReportTable = () => {
//   const [accounts, setAccounts] = useState([
//     {
//       id: 1,
//       // user: securelocalStorage.getItem('userId') || 1,  // Get user ID from local storage or use a default
//       // user: securelocalStorage.getItem('username') || 'default_username',
//       user: securelocalStorage.getItem('userPk') || '5',
//       twitter: '',
//       facebookPage: '',
//       facebookUser: '',
//       tiktok: '',
//       instagram: '',
//     },
//   ]);

//   const [reportDate, setReportDate] = useState(new Date());

//   const addAccount = () => {
//     const newAccount = {
//       id: accounts.length + 1,
//       user: securelocalStorage.getItem('userPk') || 5,
//       twitter: '',
//       facebookPage: '',
//       facebookUser: '',
//       tiktok: '',
//       instagram: '',
//     };

//     setAccounts([...accounts, newAccount]);
//   };

//   const sendReport = async () => {
//     try {
//       const apiUrl = 'http://127.0.0.1:8000//api/api/reports/';
//         // Retrieve senderId from local storage
//     // const senderId = securelocalStorage.getItem('userId');
  
//       // Prepare data for the POST request
//       const postData = accounts.map(account => ({
//         id: account.id,
//         user: account.user,
//         report_date: reportDate.toISOString().split('T')[0],
//         twitter: account.twitter,
//         facebook_page: account.facebookPage,
//         facebook_user: account.facebookUser,
//         tiktok: account.tiktok,
//         instagram: account.instagram,
//         // user: senderId,  // Include the sender's ID
//       }));
  
//       // Send POST request
//       const response = await fetch(apiUrl, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify(postData),
//       });
  
//       if (!response.ok) {
//         throw new Error('Failed to send report');
//       }
  
//       // Reset the accounts or perform any other necessary actions
//       setAccounts([
//         {
//           id: 1,
//           user: securelocalStorage.getItem('userId') || 1,
//           twitter: '',
//           facebookPage: '',
//           facebookUser: '',
//           tiktok: '',
//           instagram: '',
//         },
//       ]);
  
//       alert('Report sent to the manager!');
//     } catch (error) {
//       console.error('Error sending report:', error.message);
//       alert('Failed to send report. Please try again.');
//     }
//   };
  
  
  
  
//   const componentRef = React.useRef();

//   const handlePrint = useReactToPrint({
//     content: () => componentRef.current,
//   });

//   const handleInputChange = (index, field, value) => {
//     const updatedAccounts = [...accounts];
//     updatedAccounts[index][field] = value;
//     setAccounts(updatedAccounts);
//   };

//   return (
//     <div className="p-6">

//       <div className="mb-4">
//         <label className="mr-2">Report Date:</label>
//         <DatePicker selected={reportDate} onChange={(date) => setReportDate(date)} />
//       </div>

//       {/* <div className="bg-white p-6 rounded-md shadow-md" ref={componentRef}> */}
//       <div className="p-6 rounded-md shadow-md" style={{ backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), url(${i1})`, backgroundSize: 'cover', backgroundPosition: 'center' }} ref={componentRef}>
//         <button
//           className="mb-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
//           onClick={addAccount}
//         >
//           Add Account
//         </button>

//         <div className="overflow-x-auto">
//           <table className="min-w-full border border-gray-300">
//             <thead>
//               <tr>
//                 <th className="border-b p-2">Number</th>
//                 <th className="border-b p-2">Twitter links</th>
//                 <th className="border-b p-2">Facebook Page link</th>
//                 <th className="border-b p-2">Facebook User links</th>
//                 <th className="border-b p-2">TikTok links</th>
//                 <th className="border-b p-2">Instagram</th>
//               </tr>
//             </thead>
//             <tbody>
//               {accounts.map((account, index) => (
//                 <tr key={account.id}>
//                   <td className="border-b p-2">{account.id}</td>
//                   <td className="border-b p-2">
//                     <input
//                       type="text"
//                       value={account.twitter}
//                       onChange={(e) => handleInputChange(index, 'twitter', e.target.value)}
//                       className="w-full p-1"
//                     />
//                   </td>
//                   <td className="border-b p-2">
//                     <input
//                       type="text"
//                       value={account.facebookPage}
//                       onChange={(e) => handleInputChange(index, 'facebookPage', e.target.value)}
//                       className="w-full p-1"
//                     />
//                   </td>
//                   <td className="border-b p-2">
//                     <input
//                       type="text"
//                       value={account.facebookUser}
//                       onChange={(e) => handleInputChange(index, 'facebookUser', e.target.value)}
//                       className="w-full p-1"
//                     />
//                   </td>
//                   <td className="border-b p-2">
//                     <input
//                       type="text"
//                       value={account.tiktok}
//                       onChange={(e) => handleInputChange(index, 'tiktok', e.target.value)}
//                       className="w-full p-1"
//                     />
//                   </td>
//                   <td className="border-b p-2">
//                     <input
//                       type="text"
//                       value={account.instagram}
//                       onChange={(e) => handleInputChange(index, 'instagram', e.target.value)}
//                       className="w-full p-1"
//                     />
//                   </td>
//                 </tr>
//               ))}
//             </tbody>
//           </table>
//         </div>

//         <div className="mt-4 flex justify-between">
//           <button
//             className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
//             onClick={sendReport}
//           >
//             Send Report
//           </button>

//           <button
//             className="bg-yellow-500 hover:bg-yellow-700 text-white font-bold py-2 px-4 rounded"
//             onClick={handlePrint}
//           >
//             Save as PDF
//           </button>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default ReportTable;




// ReportTable.js
import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useReactToPrint } from 'react-to-print';
import securelocalStorage from 'react-secure-storage'
import i1 from "../assets/medemer.jpg";

const ReportTable = () => {
  const [accounts, setAccounts] = useState([
    {
      id: 1,
      user: securelocalStorage.getItem('userPk') || '5',
      twitter: '',
      facebookPage: '',
      facebookUser: '',
      tiktok: '',
      instagram: '',
    },
  ]);

  const [reportDate, setReportDate] = useState(new Date());

  const addAccount = () => {
    const newAccount = {
      id: accounts.length + 1,
      user: securelocalStorage.getItem('userPk') || 5,
      twitter: '',
      facebookPage: '',
      facebookUser: '',
      tiktok: '',
      instagram: '',
    };

    setAccounts([...accounts, newAccount]);
  };

  const sendReport = async () => {
    try {
      const apiUrl = 'https://api.eth-prosperity.com/api/api/reports/';
  
      const postData = accounts.map(account => ({
        id: account.id,
        user: account.user,
        report_date: reportDate.toISOString().split('T')[0],
        twitter: account.twitter,
        facebook_page: account.facebookPage,
        facebook_user: account.facebookUser,
        tiktok: account.tiktok,
        instagram: account.instagram,
      }));
  
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(postData),
      });
  
      if (!response.ok) {
        throw new Error('Failed to send report');
      }
  
      setAccounts([
        {
          id: 1,
          user: securelocalStorage.getItem('userPk') || '5',
          twitter: '',
          facebookPage: '',
          facebookUser: '',
          tiktok: '',
          instagram: '',
        },
      ]);
  
      alert('Report sent to the manager!');
    } catch (error) {
      console.error('Error sending report:', error.message);
      alert('Failed to send report. Please try again.');
    }
  };
  
  const componentRef = React.useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const handleInputChange = (index, field, value) => {
    const updatedAccounts = [...accounts];
    updatedAccounts[index][field] = value;
    setAccounts(updatedAccounts);
  };

  return (
    <div className="p-6">
      <div className="mb-4">
        <label className="mr-2">Report Date:</label>
        <DatePicker selected={reportDate} onChange={(date) => setReportDate(date)} />
      </div>

      {/* <div className="bg-white p-6 rounded-md shadow-md" ref={componentRef}> */}
      <div className="p-6 rounded-md shadow-md" style={{ backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), url(${i1})`, backgroundSize: 'cover', backgroundPosition: 'center' }} ref={componentRef}>
        <button
          className="mb-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          onClick={addAccount}
        >
          Add Account
        </button>

        <div className="overflow-x-auto">
          <table className="min-w-full border border-gray-300">
            <thead>
              <tr>
                <th className="border-b p-2">Number</th>
                <th className="border-b p-2">Twitter links</th>
                <th className="border-b p-2">Facebook Page link</th>
                <th className="border-b p-2">Facebook User links</th>
                <th className="border-b p-2">TikTok links</th>
                <th className="border-b p-2">Instagram</th>
              </tr>
            </thead>
            <tbody>
              {accounts.map((account, index) => (
                <tr key={account.id}>
                  <td className="border-b p-2">{account.id}</td>
                  <td className="border-b p-2">
                    <input
                      type="text"
                      value={account.twitter}
                      onChange={(e) => handleInputChange(index, 'twitter', e.target.value)}
                      className="w-full p-1"
                    />
                  </td>
                  <td className="border-b p-2">
                    <input
                      type="text"
                      value={account.facebookPage}
                      onChange={(e) => handleInputChange(index, 'facebookPage', e.target.value)}
                      className="w-full p-1"
                    />
                  </td>
                  <td className="border-b p-2">
                    <input
                      type="text"
                      value={account.facebookUser}
                      onChange={(e) => handleInputChange(index, 'facebookUser', e.target.value)}
                      className="w-full p-1"
                    />
                  </td>
                  <td className="border-b p-2">
                    <input
                      type="text"
                      value={account.tiktok}
                      onChange={(e) => handleInputChange(index, 'tiktok', e.target.value)}
                      className="w-full p-1"
                    />
                  </td>
                  <td className="border-b p-2">
                    <input
                      type="text"
                      value={account.instagram}
                      onChange={(e) => handleInputChange(index, 'instagram', e.target.value)}
                      className="w-full p-1"
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="mt-4 flex justify-between">
          <button
            className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
            onClick={sendReport}
          >
            Send Report
          </button>

          <button
            className="bg-yellow-500 hover:bg-yellow-700 text-white font-bold py-2 px-4 rounded"
            onClick={handlePrint}
          >
            Save as PDF
          </button>
        </div>
      </div>
    </div>
  );
};

export default ReportTable;