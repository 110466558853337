
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import securelocalStorage from 'react-secure-storage'
import './Navbard.css'; // Import the CSS file for custom styles
import logo from "../assets/prospority.jpg";

// import "../styles/Navbar.css";
// import BestSellers from './BestSellers';
// import GiftSets from './GiftSets';
// import Body from './Body';
// import { FaShoppingBag } from "react-icons/fa";
// import { useSelector } from 'react-redux';
// import {BsTelephonePlusFill} from "react-icons/bs";
import NavAvatar from './NavAvatar';
// import securesecurelocalStorage from 'react-secure-storage'

const Navbarr = () => {
  // <h3 className="text-xs moving-text ">WELCOME TO SYNERGY INDUSTRIAL PVT. LTD. CO. </h3>
  const [show, setShow] = useState(false);
    const [show2, setShow2] = useState(false);
    const [show3, setShow3] = useState(false);
    const [show4, setShow4] = useState(false);


    const showHandler = () => {
        setShow(true)
        setShow2(false)
        setShow3(false)
        setShow4(false)

    }




    const showHandler2 = () => {
        setShow2(true)
        setShow(false)
        setShow3(false)
        setShow4(false)

    }

    const showHandler3 = () => {
        setShow3(true)
        setShow(false)
        setShow2(false)
        setShow4(false)
    }

    const showHandler4 = () => {
        setShow4(true)
        setShow(false)
        setShow2(false)
        setShow3(false)

    }


    const dontShowHandler = () => {
        setShow(false)
        setShow2(false)
        setShow3(false)
        setShow4(false)


    }
    const isLoggedIn = !!(securelocalStorage.getItem('token') && securelocalStorage.getItem('user'));


    const handleLogout = () => {
        securelocalStorage.removeItem('token');
        securelocalStorage.removeItem('user');
        window.location = '/';; // Redirect to homepage
      };



    const [dropdownVisible, setDropdownVisible] = useState(false);
    

    const toggleDropdown = () => {
        setDropdownVisible(!dropdownVisible);
      };



    const user = JSON.parse(securelocalStorage.getItem('user'));
    const token = securelocalStorage.getItem('token');


const handlepasswordchange = () => {
    window.location.href = "/changepassword";
}


  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };
  

  return (
    <nav className="bg-gray-800">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-16">
          <div className="flex-shrink-0">
          <Link to="/">
            <div className="logo">
   <div className='flex flex-row'>
   <img src={logo} className="w-10 h-10 mb-1 rounded-full" alt="Logo" />
   <h6 className="text-xl mt-3 px-2 text-white">prosperity</h6>
        </div>
          <h3 className="text-xs text-white moving-text">Welcome</h3>
        </div>
            
            </Link>
          </div>



          <div className="">
            <div className="ml-4 flex items-center md:ml-6">

            {isLoggedIn ? (
                           
              <div className='text-gray-100 hover:text-white px-3 py-1 rounded-md text-lg font-medium'>
              <NavAvatar/>
              </div>
            ) : (
              <Link to="/login">
              <div className="text-gray-100 hover:bg-yellow-500 bg-blue-500 hover:text-black px-3 py-1 rounded-md text-lg font-medium">Login</div>
              </Link>
            )

            }
            </div>
          </div>

        </div>
      </div>

      <div className={`sm:hidden ${isMobileMenuOpen ? 'block' : 'hidden'}`} id="mobile-menu">
      </div>
    </nav>
  );
};

export default Navbarr;
